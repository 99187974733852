import React, { useState } from 'react';
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu'
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import LogoAP from '../../assets/logo-augenpermanence.svg'




export default () => {

  const [menuState, setMenuOpen] = useState({menuOpen: false});

   // This keeps the state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  // insert onStateChange at SideMenu -> Menu: onStateChange={state => handleStateChange(state)}
  const handleStateChange = state => {
    setMenuOpen(state.isOpen)
  }

  const closeMenu = () => {
    setMenuOpen({menuOpen: false})
  }

  return (
    <>
        <TopNav className="sticky-top">

            <Link to="/">
                <LogoAP className="logoap"/>
            </Link>
            
            
            <NavItems className="menu-items">
              <Link to="/faq/" >Häufige Fragen</Link>
              <Link to="/uber-uns/">Über uns</Link>
            </NavItems>

            <NavItems className="menu-items">
              <a className="menu-item-shop" href="https://augen-permanence.ch/shop/product/makula/">
                    Shop
                  </a>
            </NavItems>

            <SideMenu>
              <Menu right isOpen={ menuState.menuOpen } onStateChange={state => handleStateChange(state)}>


                <Link className="menu-item" to="/uber-uns/" onClick={() => closeMenu()}>
                  Über uns
                </Link>

                <Link to="/faq/" className="menu-item" onClick={() => closeMenu()}>
                  Häufige Fragen 
                </Link>

                <a href="https://augen-permanence.ch/shop/product/makula/" className="menu-item" onClick={() => closeMenu()}>
                  Online-Shop 
                </a>

              </Menu>
            </SideMenu>

        </TopNav>
    </>

)}

const TopNav = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1101;
  top: 0;
  height: 60px;
  align-items: center;
  box-shadow: 0px 1px 8px #19191930;
  text-decoration: none;
  position: fixed;
  @media(max-width: 990px) {
    position: fixed;
  }
`

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  line-height: 1px;
  padding: 0 32px;
  font-weight: 700;
  a {
    color: #191919;
    text-align: center;
    padding: 1em;
    text-decoration: none;
    font-size: 1em;
    transition: ease-in .2s;
    &:hover {
      color: #E66501;
    }
  }
  .menu-item-shop{
    border-radius: 30px;
    height: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #fff;
    background: #E66501;
    line-height: 1.5em;
    font-weight: 700;
    &:hover{
      color: #fff;
      background-color: #FF6F00;
      transition: ease-in .3s;
    }
  }
  .menu-item-kontakt-mob{
    border: solid 1px #283c86;
    border-radius: 30px;
    height: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #fff;
    background: #283c86;
  }
  @media(max-width: 989px) {
    display: none;
  }
`

const SideMenu =  styled.div`
    /* Individual item */
    .bm-item {
      display: inline-block;
      /* Our sidebar item styling */
      text-decoration: none;
      margin-bottom: 32px;
      color: #000;
      outline: none;
      transition: color 0.2s;
    }
    /* Change color on hover */
    .bm-item:hover {
      color: white;
    }
    /* The rest copied directly from react-burger-menu docs */
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 24px;
      height: 20px;
      right: 16px;
      top: 20px;
    }
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }
    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
      top: -10px;
    }
    /* Color/shape of close button cross */
    .bm-cross {
      background: #000;
      height: 24px;
      width: 24px;
    }
    /* General sidebar styles */
    .bm-menu {
      background: #f3f3f3;
      padding: 5em 1.5em 0;
      font-size: 1.4em;
      margin-top: -30px;
    }
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }
    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
    }
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.5);
    }
    @media(min-width: 990px) {
        display: none;
      }
`