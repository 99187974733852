import React from 'react'
import {Link} from 'gatsby'

function Footer() {
    return (
        <div className="footer-container">
            <hr></hr>
            <div className="footer-text-container">
                <div className="footer-list-item">
                    <h4>Produkte</h4>
                    <Link to="/">
                        <h5>Makula</h5>
                    </Link>
                </div>
                <div className="footer-list-item">
                    <h4>Über uns</h4>
                    <Link to="/faq/">
                        <h5>Häufige Fragen</h5>
                    </Link>
                    <Link to="/uber-uns/">
                        <h5>Über uns</h5>
                    </Link>
                </div>
                <div className="footer-list-item">
                    <h4>Online-Shop</h4>
                    <a href="https://augen-permanence.ch/shop/product/makula/">
                        <h5>Makula</h5>
                    </a>
                </div>
                <div className="footer-list-item">
                    <h4>Rechtliches</h4>
                    <a href="https://augen-permanence.ch/shop/datenschutz/">
                        <h5>Datenschutz</h5>
                    </a>
                    <a href="https://augen-permanence.ch/shop/terms-conditions/">
                        <h5>AGB</h5>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
